import React, { useState } from 'react';
import '../App.css';
import Header from '../components/Header';
import MAN_info from '../components/Alert';
import TabsComponent from '../components/TabsComponent';
import useHandleQuery from '../utils/useHandleQuery';
import CountCards from '../components/CountsCard';
import axios from "axios";
import Modals from '../components/Modal';
import { useGraphContext } from '../GraphContext';
import { useResetContext } from '../ResetContext';
import forward_arrow from '../assets/forward_arrow.png';
import FiltersModalContent from '../components/FiltersModalContent';
import { Alert, Box, Modal } from '@mui/material';
import filters_closing_icon from '../assets/filters_closing_icon.png';

function Mainscreen() {
  const [activeTab, setActiveTab] = useState(0);  //Set the initial tab as index 0 i.e., Filters
  const [subTab, setSubTab] = useState(0);
  const [options, setOptions] = useState([]);
  const [initialOptions, setInitialOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [tableData, setTableData] = useState([]);
  const [initialTableData, setInitialTableData] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(500000);
  const [initialized, setInitialized] = useState(false);
  const [cardsData, setCardsData] = useState({});  //Countcards State
  const [searchVins, setSearchVins] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [activeFilter, setActiveFilter] = useState(''); // Track the selected filter
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [modalFilterContent, setModalFilterContent] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [checkedfilters, setcheckedfilters] = useState([]);
  const [appliedfilters, setappliedfilters] = useState({});
  const [updatedselect, setupdatedselect] = useState({});  
  const [mainfilters, setmainfilters] = useState({});
  const [disabled, setDisabled] = useState(false);
  const handleQuery = useHandleQuery();
  const { filters, setFilters,updateGraphData } = useGraphContext(); 
  const { reset, setreset} = useResetContext();
  const [filtersModified, setFiltersModified] = useState(false);
  const [submittedFilters, setSubmittedFilters] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false); // Add this line to your state
  const [initialFiltersState, setInitialFiltersState] = useState({
      selectedOptions,
      minValue,
      maxValue,
      searchVins,
      csvFile
  });

  const headerNames = {
    'Year of operation': 'Year of operation', 
    'start_of_operation__year': 'Year of commissioning',
    'segment': 'Segment',
    'type_number': 'Type number',
    'vehicle_type': 'Vehicle type',
    'series': 'Series',
    'cabin': 'Cabin',
    'body_built': 'Body built',
    'power__hp': 'Power [hp]',
    'wheelbase__mm': 'Wheelbase [mm]',
    'wheel_configuration': 'Wheel configuration',
    'suspension': 'Suspension type',
    'gross_vehicle_weight__t': 'Gross vehicle weight [t]',
    'gross_train_weight__t': 'Gross train weight [kg]',
    'axle_ratio_num': 'Axle ratio number',
    'latest_hardware_serial_nr': 'ECU hardware serial number',
    'latest_software_serial_nr': 'ECU software serial number',
    'ecu_fullname': 'ECU name',  
    'type_number_tg3':'Type number TG3 logic',
    'maturity':'Maturity',  
    'engine_type':'Engine family',
    'emission_standard':'Emission standard',
    'vehicle_generation': 'Vehicle generation',
    'model_year': 'Model year',
    'bus_engine_family': 'Bus engine family',
    'electronic_platform': 'Bus electronic platform'  
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none', 
    // height: '10vh'
  };

  // Check if filters have been modified
  const handleFiltersModification = () => {
      if (submitClicked) {
          // Check if current filters differ from initial state
          const filtersChanged = (
              JSON.stringify(selectedOptions) !== JSON.stringify(initialFiltersState.selectedOptions) ||
              minValue !== initialFiltersState.minValue ||
              maxValue !== initialFiltersState.maxValue ||
              searchVins !== initialFiltersState.searchVins ||
              csvFile !== initialFiltersState.csvFile
          );

          if (filtersChanged) {
              setFiltersModified(true);
              console.log('Filters have been modified')
          }
      }
  };
  
  const handleSubmit = async () => {
    setSubmitClicked(true);
    setDisabled(true); // Disable the filters
    setShowAlert(false);

    setShowModal(true);
    setModalContent('Generating graphs...');

    // Reset graph data context when filters change
    updateGraphData(null); // Assuming updateGraphData accepts null to clear the data
    setCardsData({});
    setSubmittedFilters({}); // Reset filters
 
    const selectedValues = {
      ...selectedOptions,
      minValue: minValue,
      maxValue: maxValue,
      vin_short: [...searchVins.split(',').map(vin_short => vin_short.trim()).filter(Boolean)],
    };
    console.log('selectedValues',selectedValues)
    try {
      await handleQuery({ selectedValues, file: csvFile });
      setModalContent('Generated graphs successfully');
      setSubmittedFilters(appliedfilters); // Store the current applied filters
      setError(false); // Reset error on successful generation
    } catch (error) {
      // Handle error
      setModalContent('Failed to generate graphs');
      setError(true); // Set error to true
    } finally {
      // Close modal after a delay
      setTimeout(() => {
        setShowModal(false);
        setDisabled(false); // Enable the filters
        // setSubmitClicked(false); // Reset to false when done
      },2000); // Adjust the duration as needed
    }
  };

  const handleUpdate = () => {
    let baseURL;
    if (window.location.hostname === 'vuca-prod.simbaanalytics-dev.rio.cloud') {
      baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
    } else if (window.location.hostname === 'md8gpfz52p.eu-west-1.awsapprunner.com') {
      baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
    } else if (window.location.hostname === 'vuca-dev.simbaanalytics-dev.rio.cloud') {
      baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
    } else if (window.location.hostname === '8jmq9udcvt.eu-west-1.awsapprunner.com') {
      baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
    } else {
      baseURL = 'http://localhost:5000'; 
    }

    setShowFilterModal(true);
    setModalFilterContent('Updating ...');
    setUpdateClicked(true); // Mark that the update button was clicked
    
    const selectedValues = {
        ...selectedOptions,
        minValue: minValue,
        maxValue: maxValue,
        vin_short: [...searchVins.split(',').map(vin_short => vin_short.trim()).filter(Boolean)],
        subTab: subTab 
    };

    // Create a FormData object
    const formData = new FormData();
    formData.append('data', JSON.stringify(selectedValues));
    if (csvFile) {
        formData.append('file', csvFile);
    }

    axios.post(`${baseURL}/api/dynamic_filtering`, formData)
    .then(response => {
        const { all_options, filtered_options, tables } = response.data;

       // Check if all filters except "Year of operation" are empty
       const allFiltersEmpty = filtered_options.every(option => 
        option.label === 'Year of operation' ? option.values.length > 0 : option.values.length === 0
        );

        if (allFiltersEmpty) {
            setModalFilterContent('Invalid combination - please check your inputs');
            setTimeout(() => {
                setShowFilterModal(false);
                setUpdateClicked(false);
            }, 4000);
            return; // Exit early to avoid further processing
        }
        // Update all options
        // setOptions(all_options);

        // Update selected options to only include those from filtered_options
        const updatedSelections = filtered_options.reduce((acc, column) => {
            acc[column.label] = column.values; // Select all options for this column
            return acc;
        }, {});

        setSelectedOptions(prevSelected => {
            const deselectedOptions = { ...prevSelected };

            // Update selected options with those from filtered_options
            Object.keys(deselectedOptions).forEach(column => {
                if (updatedSelections[column]) {
                    deselectedOptions[column] = updatedSelections[column];
                } else {
                    // Deselect all options if not in the filtered list
                    deselectedOptions[column] = [];
                }
            });

            return deselectedOptions;
        });
        console.log('setSelectedOptions',selectedOptions)
        // setappliedfilters(selectedOptions);

        // Remove columns that are in appliedfilters from options
        const newOptions = { ...options };
        Object.keys(appliedfilters).forEach(column => {
            delete newOptions[column]; // Delete the column from options if it's in appliedfilters
        });

        setOptions(newOptions); // Update options state with the filtered options
        console.log('setOptions',options)

        setTableData(tables);

        setModalFilterContent('Filters updated successfully');
        setTimeout(() => {
            setShowFilterModal(false);
            setUpdateClicked(false);
        }, 1000);
    })
    .catch(error => {
        console.log('There was an error in dynamically filtering the data', error);
        setModalFilterContent('Failed to update');
        setTimeout(() => {
            setShowFilterModal(false);
            setUpdateClicked(false);
        }, 1000);
    });
  };

  const handleReset = () => {
    setSubmitClicked(false);
    setSubmittedFilters({});
    setUpdateClicked(false);
    setDisabled(false);
    setSubTab(0);
    setActiveTab(0);
    const newOptions = {};
    reset.forEach(column => {
        newOptions[column.label] = column.values;
    });
    updateGraphData(null); // Reset the graph data

    setOptions(newOptions);
    setSelectedOptions(newOptions);

    setTableData(initialTableData);

    setappliedfilters({ ...{} });
    console.log('update graph data',updateGraphData)
    // Ensures a new object is created

    setMinValue(0);
    setMaxValue(500000);

    // Reset uploaded and searched Vins
    setCsvFile(null);
    setUploadedFileName('');
    setSearchVins('');   // Reset searched Vins

    // Reset CountCards data
    setCardsData({});
    setFilters(null);
    setActiveFilter('');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    if (updateClicked) {
      // Show a different message if update was clicked
      setModalFilterContent('Please wait, the update is in progress. You cannot switch between the tab when update is in progress.');
      setShowFilterModal(true); // Show the modal
    } else if (submitClicked) {
      // Show the existing message if submit was clicked
      setModalFilterContent('To modify the product selection, it is necessary to first click on the "Clear all filters" button.');
      setShowFilterModal(true); // Show the modal
      setTimeout(() => {
          setShowFilterModal(false);
      }, 5000);
    } else {
      setSubTab(newValue); // Change tab if not submitting
    }
  };

  const handleDownload = async (option) => {
    let baseURL;
    if (window.location.hostname === 'vuca-prod.simbaanalytics-dev.rio.cloud') {
      baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
    } else if (window.location.hostname === 'md8gpfz52p.eu-west-1.awsapprunner.com') {
      baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
    } else if (window.location.hostname === 'vuca-dev.simbaanalytics-dev.rio.cloud') {
      baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
    } else if (window.location.hostname === '8jmq9udcvt.eu-west-1.awsapprunner.com') {
      baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
    } else {
      baseURL = 'http://localhost:5000'; 
    }
      // Check if any data has been submitted
      if (!submitClicked) {
          setShowModal(true);
          setModalContent('Nothing is submitted to download.');
          setTimeout(() => {
              setShowModal(false);
          }, 2000); // Adjust the duration as needed
          return;
      }
  
      // Proceed with download if data has been submitted
      setShowModal(true);
      setModalContent('Downloading ...');
  
      const selectedValues = {
          ...selectedOptions,
          minValue: minValue,
          maxValue: maxValue,
          vin_short: [...searchVins.split(',').map(vin_short => vin_short.trim()).filter(Boolean)],
      };
  
      // Create a FormData object
      const formData = new FormData();
      formData.append('data', JSON.stringify(selectedValues));
      formData.append('option', option);
  
      // Append the CSV file if it exists
      if (csvFile) {
          formData.append('file', csvFile);
      }
  
      try {
          // Make an HTTP POST request to the server to fetch the file
          const response = await axios({
              url: `${baseURL}/download-data`, // Adjust URL as necessary for POST
              method: 'POST',
              data: formData,
              responseType: 'blob', // Important for file downloads
          });
  
          // Create a URL for the blob data
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  
          // Create a temporary link element
          const link = document.createElement('a');
          link.href = downloadUrl;
  
          // Set the download attribute with a filename
          link.setAttribute('download', `${option}.xlsx`); // Use the option as part of the filename
  
          // Append the link to the body and click it to trigger the download
          document.body.appendChild(link);
          link.click();
  
          // Remove the link from the document
          link.remove();
  
          // Update modal content on success
          setModalContent('Downloaded successfully!');
          setTimeout(() => {
              setShowModal(false);
          }, 2000); // Adjust the duration as needed
      } catch (error) {
          // Log any errors to the console
          console.error('Error downloading file:', error);
  
          // Update modal content on failure
          setModalContent('Failed to download');
          setTimeout(() => {
              setShowModal(false);
          }, 2000); // Adjust the duration as needed
      }
  };

  const handleOpenAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const hasFilters = submittedFilters && Object.values(submittedFilters).some(value => value.length > 0);

  return (
    <div className="App">
        <Header />
        <MAN_info />
        <div className='main-content'> 
          <div style={{ display: 'flex', flexDirection: 'row', height: '150px', backgroundColor: 'rgb(248, 248, 248)', borderRadius: '4px', margin: '50px 50px 20px 50px'  }}>
            <div style={{ justifyContent: 'center', flex:1 }}>
              <CountCards cardsData={cardsData} setCardsData={setCardsData}/>
            </div>
          </div> 

          <div style={{ display: 'flex', backgroundColor: 'white', padding: '10px', margin: '0px 60px 10px 60px', color: 'rgb(48, 60, 73)', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontFamily: 'MAN EUROPE_Bold', fontSize: '20px', marginLeft: '7px', marginRight: '20px' }}>Filters</span>
              {submitClicked && hasFilters ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '10px', alignItems: 'center' }}>
                  {Object.entries(submittedFilters).map(([key, value]) => (
                    value && value.length > 0 && (
                      <div key={key} style={{ margin: '10px', padding: '10px', background: '#F8F8F8', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: 'none',
                            background: 'transparent',
                            cursor: 'pointer',
                            fontFamily: 'MAN EUROPE_regular', // Change font family for applied filters
                            fontWeight: 'bold',
                            fontSize: '16px', // Change font size for applied filters
                          }}
                        >
                          {headerNames[key] || key} {/* Use headerNames to get the display name */}
                        </div>
                      </div>
                    )
                  ))}
                </div>
              ) : (
                <p style={{ marginLeft: '10px', fontFamily: 'MAN EUROPE_regular',fontWeight: 'bold', fontSize: '16px' }}>No filter selected</p> // Change font for the message
              )}
            </div>

            <img 
              src={forward_arrow} 
              style={{ width: '15px', height: '19px', margin: '6px' }} 
              onClick={handleOpenAlert}
            />
          </div>

          <Modal
            open={showAlert}
            onClose={() => {}} // Prevent closing when clicking outside
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <Alert 
                icon={false} // Disable the default icon
                sx={{ 
                    backgroundColor: 'rgb(248, 248, 248)', // Grey background color
                  }}>
                  {/* New div for "Choose Filters" */}
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', padding: '20px',marginBottom: '20px' }}>
                      <div style={{ 
                          fontFamily: 'MAN EUROPE_Bold', 
                          fontSize: '20px', 
                          color: 'rgb(48, 60, 73)' 
                      }}>
                          Choose Filters
                      </div>
                      <img 
                          src={filters_closing_icon} 
                          alt="Close"
                          style={{ width: '20px', height: '20px', cursor: 'pointer' }} // Adjust size as needed
                          onClick={handleCloseAlert}
                      />
                  </div>
                  <FiltersModalContent
                    subTab={subTab}
                    setSubTab={setSubTab}
                    options={options}
                    setOptions={setOptions}
                    selectedOptions={selectedOptions}
                    setInitialOptions={setInitialOptions}
                    setSelectedOptions={setSelectedOptions}
                    setCardsData={setCardsData}
                    tableData={tableData}
                    setTableData={setTableData}
                    setInitialTableData={setInitialTableData}
                    minValue={minValue}
                    setMinValue={setMinValue}
                    maxValue={maxValue}
                    setMaxValue={setMaxValue}
                    initialized={initialized}
                    setInitialized={setInitialized}
                    searchVins={searchVins}
                    setSearchVins={setSearchVins}
                    uploadedFileName={uploadedFileName}
                    setUploadedFileName={setUploadedFileName}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    csvFile={csvFile}
                    setCsvFile={setCsvFile}
                    submitClicked={submitClicked}
                    updateClicked={updateClicked}
                    checkedfilters={checkedfilters}
                    setcheckedfilters={setcheckedfilters}
                    appliedfilters={appliedfilters}
                    setappliedfilters={setappliedfilters}
                    updatedselect={updatedselect} 
                    setupdatedselect={setupdatedselect}
                    mainfilters={mainfilters}
                    setmainfilters={setmainfilters}
                    filtersModified={filtersModified}
                    setFiltersModified={setFiltersModified}
                    disabled={disabled}
                    handleUpdate={handleUpdate}
                    handleReset={handleReset}
                    handleSubmit={handleSubmit}
                    handleSubTabChange={handleSubTabChange}
                    handleFiltersModification={handleFiltersModification}
                    showFilterModal={showFilterModal} 
                    setShowFilterModal={setShowFilterModal}
                    modalFilterContent={modalFilterContent} 
                    headerNames={headerNames}
                  />
              </Alert>
            </Box>
        </Modal>

          <TabsComponent 
            activeTab = {activeTab} 
            handleTabChange = {handleTabChange} 
            submitClicked={submitClicked}
            handleDownload={handleDownload}
            error={error}
          />
        </div>   
      <Modals show={showModal} content={modalContent} onClose={() => setShowModal(false)} />
    </div>
  );
}

export default Mainscreen;